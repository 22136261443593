<template>
  <div class='relative investment-auto-suggest'>
    <span class='inline-block mr-2'>{{investmentName}}</span>
    <button @click='openSelect' class='text-blue-700 text-sms py-2 px-4 rounded border'>선택</button>
    <v-popover event='click' offset='8' :auto-hide='false' placement='left-start' trigger='manual' :open='openSelectInvestment && selectedSuggest === "investment"' class='absolute'>
      <div slot='popover' class='w-64 relative' style='height:20rem;'>
        <button class='border border-transparent py-1 px-2 rounded absolute text-sm text-gray-500 bg-gray-100 shadow' style='top: 10px; right: 10px;' @click='closeSelectInvestment'>닫기</button>
        <vue-autosuggest
          v-model='investmentSearchText'
          ref='investmentSearch'
          :suggestions='filteredOptions'
          :get-suggestion-value='suggestionValue'
          :should-render-suggestions='shouldShowIsh'
          :input-props="{id:'autosuggest__input', ref:'inputTextbox', placeholder:'검색어를 입력하세요', class:'border px-4 py-3 rounded-md w-full'}"
          @input='onInputChange'
          @selected='selectHandler'>
          <template slot-scope="{suggestion}">
            <span class='text-gray-600 my-suggestion-item'>{{suggestion.item.investment_name}}</span>
          </template>
          <template slot='after-suggestions'>
            <div class='p-2 text-center text-sm' v-if='emptySearchString'>{{emptyMessage}}</div>
          </template>
        </vue-autosuggest>
      </div>
    </v-popover>
  </div>
</template>

<script>

import { mapActions, mapState, mapMutations } from 'vuex'
import { VueAutosuggest }       from 'vue-autosuggest'

export default {
  name: 'InvestmentAutoSuggest',
  components: {
    VueAutosuggest,
  },
  props: ['investmentId'],
  data () {
    return {
      filteredOptions: [],
      openSelectInvestment: false,
      investmentSearchText: '',
    }
  },
  watch: {
    openSelectInvestment: {
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.investmentSearch.$refs.inputTextbox.focus()
          })
        }
      },
      immediate: true
    },
    showPopover: {
      handler: function (newVal) {
        if (newVal === false) {
          this.closeSelectInvestment()
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('investments', [
      'investmentsSearch',
    ]),
    ...mapState([
      'showPopover',
      'selectedSuggest',
    ]),
    emptyMessage () {
      return `please search by investment name`
    },
    emptySearchString () {
      return this.investmentSearchText.trim() === '' && this.hasNoResults
    },
    noExactMatch () {
      return !this.emptySearchString &&
              this.investmentsSearch.findIndex(investment => investment.investment_name === this.investmentSearchText) === -1
    },
    hasNoResults () {
      return this.filteredOptions.length == 0 || this.filteredOptions[0].data.length == 0
    },
    showEmptyMessage () {
      return this.emptySearchString && this.hasNoResults
    },
    investment () {
      return this.investmentsSearch.find((record) => record.id === this.investmentId)
    },
    investmentName () {
      return (this.investment) ? `${this.investment.investment_name}` : ''
    },
  },
  methods: {
    ...mapActions('investments', [
      'getInvestmentsSearch',
    ]),
    ...mapMutations([
      'setShowPopover',
      'setSelectedSuggest',
    ]),
    closeSelectInvestment () {
      this.openSelectInvestment = false
      this.setShowPopover(false)
      this.setSelectedSuggest('')
    },
    openSelect () {
      this.openSelectInvestment = true
      this.setShowPopover(true)
      this.setSelectedSuggest('investment')
    },
    suggestionValue (suggestion) {
      return suggestion.item.investment_name
    },
    onInputChange (text) {
      let filteredData
      if (text === '' || text === undefined) {
        filteredData = this.investmentsSearch
      }
      else {
        filteredData = this.investmentsSearch.filter(item => {
          return item.investment_name.toLowerCase().indexOf(text.toLowerCase()) > -1
        })
      }

      this.filteredOptions = [{
        data: filteredData
      }]
    },
    selectHandler (selected) {
      if (selected === null) {
        // nothing, maybe create later
      } else {
        this.investmentSearchText = selected.item.investment_name
        this.updateSelectedInvestment(selected.item)
      }
    },
    shouldShowIsh (size, loading) {
      return  size >= 0 && !loading
    },
    updateSelectedInvestment (investment) {
      this.$emit('update-selected-investment', investment.id)
      this.closeSelectInvestment()
    },
  },
  mounted () {
    this.$refs.investmentSearch.$refs.inputTextbox.addEventListener("keyup", ({key}) => {
      if (key === "Escape") {
        this.investmentSearchText = ''
        this.closeSelectInvestment()
      }
    })
    this.getInvestmentsSearch().then(() => {
      this.onInputChange(this.investmentName)
    })
  }
}
</script>
